<template>
  <GMapMap
      :class="{ fullmap: !showpopup, smallmap: showpopup }"
      :center="start"
      :zoom="5"
      ref="myMapRef"
      :options="{
                      zoomControl: false,
                      mapTypeControl: false,
                      scaleControl: true,
                      streetViewControl: false,
                      rotateControl: true,
                      fullscreenControl: false,
                      gestureHandling: 'greedy',
                }"
      map-type-id="terrain"
      @bounds_changed="handleBoundsChanged"
  >
    
      <GMapMarker
          :key="index"
          v-for="(m, index) in pins"
          :position="{lat: m.lat, lng: m.lng}"
          :icon="{
              url: iconTypes[m.type],
              scaledSize: {width: 64, height: 64},
            }"
          :clickable="true"
          :draggable="false"
          @click="selectPin(m)"
      />

    <GMapMarker
        v-if="showpopup && selectedPin == null"
        :position="{lat: center.lat(), lng: center.lng()}"
        :clickable="false"
        :draggable="false"
    />
  </GMapMap>

  <GMapAutocomplete
      id="searchbox"
      placeholder="Zoeken naar een locatie..."
      @place_changed="goToPlace"
      v-if="!showpopup"
    >
  </GMapAutocomplete>

  <!-- <button class="switch-type-pin-button pin-button" @click="login()">
    Login
  </button> -->

  <button class="switch-type-pin-button pin-button" v-if="!showpopup" @click="switchType()">
    O
  </button>

  <button class="add-pin-button pin-button" v-if="!showpopup" @click="openAddPinPopup()">
    +
  </button>

  <OnboardingOverlay
    @close="hideOnboarding()"
    v-if="me != null && (me.onboardingFinished == null || me.onboardingFinished == false)">
  </OnboardingOverlay>

  <AddPinPopup
    v-if="showpopup && selectedPin == null"
    @close="hidePopup()"
    @save="createNewPin">
  </AddPinPopup>
  <!--<LocationSelector></LocationSelector>-->
  <InfoPopup 
    v-if="showpopup && selectedPin != null"
    @close="hidePopup()"
    @delete="deletePin"
    :pin="selectedPin">
  </InfoPopup>

</template>

<script>
import { ref, watch } from "vue";

let GMAP = null;

//import LocationSelector from './components/LocationSelector.vue'
import InfoPopup from './components/InfoPopup.vue'
import AddPinPopup from './components/AddPinPopup.vue'
import OnboardingOverlay from './components/OnboardingOverlay.vue'

export default {
  name: 'App',
  setup() {
    const myMapRef = ref();

    watch(myMapRef, googleMap => {
      if (googleMap) {
        googleMap.$mapPromise.then(map=> {
          GMAP = map;
          GMAP.setTilt(45);
        })
      }
    });
    
    return {
      myMapRef
    }
  },
  async mounted() {

    const signInStatus = localStorage.getItem('signInStatus');

    let uri = window.location.href.split('?');
    if (signInStatus == 'waiting' && uri.length == 2) {

      localStorage.setItem('signInStatus', 'done');

      let vars = uri[1].split('&');
      let getVars = {};
      let tmp = '';
      vars.forEach(function(v) {
        tmp = v.split('=');
        if(tmp.length == 2)
          getVars[tmp[0]] = tmp[1];
      });

      if (getVars.code != null) {
        const res = await fetch(`/.netlify/functions/me`, {
          method: 'POST',
          body: JSON.stringify({
            code: getVars.code,
          }),
          headers: {
              'Content-Type': 'application/json'
          }
        });

        let token = await res.json();
        if(token.token != null) {
          window.token = token.token;
          window.me = token.me;
          this.me = token.me;
        }
      }

    } else {

      localStorage.setItem('signInStatus', 'waiting');

      if (window.location.hostname === "localhost") {
        window.location = `https://api.kazoulimburg.be/auth?client_id=tripadvisor_stag&response_type=code&state=kazou`;
      } else {
        window.location = `https://api.kazoulimburg.be/auth?client_id=tripadvisor_prod&response_type=code&state=kazou`;
      }


    }


  },
  components: {
    //LocationSelector,
    InfoPopup,
    AddPinPopup,
    OnboardingOverlay
  },
  methods: {
    goToPlace(event) {
      if (event.geometry != null) {
      GMAP.fitBounds(event.geometry.viewport);

      }
    },
    switchType() {
      GMAP.setMapTypeId(GMAP.getMapTypeId() === 'terrain' ? 'satellite' : 'terrain');
    },
    hidePopup() {
      this.showpopup = false;
    },
    async hideOnboarding() {
      this.me.onboardingFinished = true;

      const options = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${window.token}`
          }
      }

      // send GET request
      await fetch(`/.netlify/functions/finishonboarding`, options);
    },
    async deletePin(pin) {

      const options = {
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${window.token}`
          }
      }
      
      this.comment = '';

      // send DELETE request
      await fetch(`/.netlify/functions/deletepin?identifier=${pin._id}`, options);

      await this.loadPins();
      this.hidePopup();
    },
    async createNewPin(event) {

      const options = {
          method: 'POST',
          body: JSON.stringify({
            name: event.name,
            description: event.description,
            lat: this.center.lat(),
            lng: this.center.lng(),
            image: null,
            type: event.type
          }),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${window.token}`
          }
      }
      
      this.comment = '';

      // send POST request
      await fetch(`/.netlify/functions/createpin`, options);

      await this.loadPins();
      this.hidePopup();
    },
    selectPin(pin) {

      if (this.showpopup) {
        this.hidePopup();
      } else {
        this.showpopup = true;
        this.selectedPin = pin;
        GMAP.setCenter({ lat: this.selectedPin.lat, lng: this.selectedPin.lng });
      }

    },
    openAddPinPopup() {
      this.showpopup = true;
      this.selectedPin = null;
    },
    loadPins: async function(lngMin, lngMax, latMin, latMax, typeToSearch) {
      this.pins = await (await fetch(`/.netlify/functions/pins?lngMin=${lngMin}&lngMax=${lngMax}&latMin=${latMin}&latMax=${latMax}&type=${typeToSearch}`, { headers: { 'Authorization': `Bearer ${window.token}` } })).json();
    },
    handleBoundsChanged() {
      if (this.pinLoaderTimer != null) {
        clearTimeout(this.pinLoaderTimer);
      }
      this.pinLoaderTimer = setTimeout(() => {
        let bounds = GMAP.getBounds();
        let lngMin = bounds.getSouthWest().lng();
        let lngMax = bounds.getNorthEast().lng();
        let latMin = bounds.getSouthWest().lat();
        let latMax = bounds.getNorthEast().lat();

        let deltaLat = latMax - latMin;
        let deltaLng = lngMax - lngMin;

        lngMin -= deltaLng / 2;
        lngMax += deltaLng / 2;
        latMin -= deltaLat / 2;
        latMax += deltaLat / 2;

        this.center = GMAP.getCenter();

        let zoom = GMAP.getZoom();
        let typeToSearch = 'Resort';

        if (zoom >= 10) {
          typeToSearch = 'ALL';
        }

        this.loadPins(lngMin, lngMax, latMin, latMax, typeToSearch);
      }, 500);
    }
  },
  data() {
    return {
      showpopup: false,
      center: {lat: 51.093048, lng: 6.842120},
      start: {lat: 51.093048, lng: 6.842120},
      selectedPin: null,
      pinLoaderTimer: null,
      pins: [],
      me: null,
      token: null,
      iconTypes: {
        'Resort': require('@/assets/icon-192x192.png'),
        'Activity': require('@/assets/activity-pin.png'),
        'Leisure': require('@/assets/park-pin.png'),
        'Food': require('@/assets/food-pin.png'),
        'Others': require('@/assets/question-pin.png'),
      }
    }
  }
}
</script>

<style>

  :root {
    --color-primary: #00cf97;
    --color-primary-hover: #10dfa7;
    --color-primary-active: #00bf87;
    --color-primary-disabled: #6bd3b7;

    --color-secondary-action: #dfdfdf;

    --color-text: #2c3e50;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: var(--color-text);
  }

  .fullmap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;animation-duration: 0.3s;
    animation-name: small-to-full;
  }

  @keyframes small-to-full {
    0% {
      height: 30vh;
    }
    100% {
      height: 100vh;
    }
  } 

  .mark-on-map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    z-index: 100;
    display: none;
  }

  #searchbox {
    position: absolute;
    top: 10px;
    left: 10px;
    width: calc(100% - 60px);
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border: 0px;
    border-radius: 25px;
    height: 30px;
    z-index: 100;
  } 

  .pin-button {
    width: 50px;
    height: 50px;
    background-color: var(--color-primary);
    border-radius: 50%;
    border: none;
    color: #444;
    font-size: 30px;
    cursor: pointer;
    outline: none;
  }
  
  .add-pin-button {
    position: absolute;
    bottom: 20px;
    right: 10px;
  }

  .switch-type-pin-button {
    position: absolute;
    bottom: 80px;
    right: 10px;
  }

  .smallmap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 30vh;
    animation-duration: 0.3s;
    animation-name: full-to-small;
  }

  @keyframes full-to-small {
    0% {
      height: 100vh;
    }
    100% {
      height: 30vh;
    }
  } 

  body {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
  }

  .popup {
    position: absolute;
    padding-left: 20px;
    padding-right: 20px;
    bottom: 0;
    width: calc(100% - 40px);
    height: 69vh;
    background-color: rgb(255, 255, 255);
    z-index: 100;
    animation-duration: 0.3s;
    animation-name: fadeIn;
  }

  @keyframes fadeIn {
    0% {
      height: 0vh;
    }
    100% {
      height: 69vh;
    }
  } 

  .header {
    display: flex;
    flex-direction: row;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgb(255, 255, 255);
    border-bottom: 1px solid rgb(204, 204, 204);
  }

  .header .icon {
    flex-grow: 0;
    width: 40px;
  }

  .header .title {
    flex-grow: 1;
  }

  .header .close {
    cursor: pointer;
    flex-grow: 0;
    width: 40px;
  }

  .content {
    height: calc(100% - 90px);
    overflow: auto;
  }

  .textbox {
    flex-grow: 1;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 10px;
    padding: 10px;
    margin-right: 10px;
    font-size: 16px;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: rgb(51, 51, 51);
    background-color: rgb(255, 255, 255);
  }

  .button-main {
    width: 100%;
    padding: 10px;
    border-radius: 18px;
    font-size: 16px;
    border: none;
    background-color: var(--color-primary);
  }

  .button-main:hover {
    background-color: var(--color-primary-hover);
  }

  .button-main:active {
    background-color: var(--color-primary-active);
  }

  .button-main:disabled {
    background-color: var(--color-primary-disabled);
  }

</style>
