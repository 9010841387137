<template>
  <div class="popup">
    <div class="xonmap">X</div>

    <div class="header">
      <div class="icon">
        
      </div>
      <div class="title">
        <h1>Plaats een nieuwe pin</h1>
      </div>
      <div class="close">
        <h1 @click="close">&#215;</h1>
      </div>
    </div>

    <div class="content">
      <div class="margin-top-bottom">
        <input class="text-title" type="text" placeholder="Naam" v-model="name" />
      </div>
      <div class="margin-top-bottom">
        <input type="radio" id="Resort" value="Resort" v-model="type" /><label for="Resort">Verblijf</label>
        <input type="radio" id="Activity" value="Activity" v-model="type" /><label for="Activity">Profilering</label>
        <input type="radio" id="Leisure" value="Leisure" v-model="type" /><label for="Leisure">Speel Terein</label>
        <input type="radio" id="Food" value="Food" v-model="type" /><label for="Food">Eten</label>
        <input type="radio" id="Others" value="Others" v-model="type" /><label for="Others">Overige</label>
      </div>
      <div class="margin-top-bottom">
        <textarea class="textbox description-box" v-model="description" placeholder="Beschrijving van de locatie..."></textarea>
      </div>
      <div class="margin-top-bottom">
        <button v-if="!loading && name != '' && description != ''" @click="save" class="button-main">Plaats</button>
        <button v-if="loading || name == '' || description == ''" disabled class="button-main"><span v-if="loading">Plaatsen...</span><span v-if="!loading">Plaats</span></button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AddPinPopup',
  emits: ['close', 'save'],
  components: {

  },
  methods: {
    close: function() {
      this.$emit('close');
    },
    save: function() {
      this.loading = true;
      this.$emit('save', { name: this.name, description: this.description, type: this.type });
    }
  },
  data() {
    return {
      name: '',
      description: '',
      type: 'Resort',
      loading: false
    }
  }
}
</script>

<style scoped>
  .xonmap {
    position: absolute;
    top: calc(-15.5vh - 15px);
    left: calc(50% - 15px);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
  }

  .text-title {
    width: calc(100% - 40px);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 20px;
    font-weight: bold;
    color: #333;
    text-align: center;
  }

  .description-box {
    width: calc(100% - 22px);
    
  }

  .margin-top-bottom {
    margin-top: 10px;
    margin-bottom: 10px;
  }
</style>
