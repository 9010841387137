import { createApp } from 'vue'
import App from './App.vue'

import VueGoogleMaps from '@fawmi/vue-google-maps'
import './registerServiceWorker'

window.ACCESS_TOKEN = null;

createApp(App)
.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCb_MMp61keJuEBUHTwIzpTZcW9rfiEUik',
        libraries: 'places',
    },
}).mount('#app')
