<template>
  <div class="popup">
    <div class="header">
      <div class="icon">
        
      </div>
      <div class="title">
        <h1>
          {{ pin.name }}
          <button v-if="pin.user && pin.user._id == currentUser.userid" @click="deletePin" class="button-main">Verwijder</button>
        </h1>
        <small v-if="pin.user">by {{ pin.user.voornaam }} {{ pin.user.naam }}</small>
      </div>
      <div class="close">
        <h1 @click="close">&#215;</h1>
      </div>
    </div>
    
    <div v-if="loading">
      <p>Loading...</p>
    </div>

    <div class="content" v-if="!loading">
      <p class="text-center">{{ fullPin.description }}</p>
      
      <div class="reply">
        <textarea class="textbox" v-model="commenttext" placeholder="Voeg een nieuw bericht toe..."></textarea>
        <h1 class="replybutton" @click="addReply(commenttext, pin._id)">&#10148;</h1>
      </div>
      
      <hr class="comment-divider" />

      <div v-if="comments.length == 0" class="text-center">
        <p>Er zijn nog geen reacties</p>
      </div>

      <div class="comment" v-for="comment in comments" :key="comment._id" >
        <b v-if="comment.user != null">{{ comment.user.voornaam }} {{ comment.user.naam }}</b><b v-if="comment.user == null">Anoniem</b> - <span>{{ comment.createdAt }}</span>
        <p>{{ comment.comment }}</p>
        <div class="sub-comment" v-for="subComment in comment.comments" :key="subComment.createdAt">
          <b v-if="subComment.user != null">{{ subComment.user.voornaam }} {{ subComment.user.naam }}</b><b v-if="subComment.user == null">Anoniem</b> - <span>{{ subComment.createdAt }}</span>
          <p>{{ subComment.comment }}</p>
        </div>
        <div v-if="subcommentfor != comment._id" class="sub-comment-reply" @click="openSubComment(comment)">
          &#10148; Antwoorden
        </div>
        <div class="reply" v-if="subcommentfor == comment._id">
          <textarea class="textbox" v-model="subcommenttext" placeholder="Voeg een nieuw bericht toe..."></textarea>
          <h1 class="replybutton" @click="addCommentReply(subcommenttext, subcommentfor)">&#10148;</h1>
        </div>
        <hr class="comment-divider" />
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: 'InfoPopup',
  emits: ['close', 'delete'],
  components: {

  },
  mounted: async function() {
    this.reloadPin();
  },
  methods: {
    close: function() {
      this.$emit('close');
    },
    reloadPin: async function() {
      this.loading = true;
      
      let result = await (await fetch(`/.netlify/functions/pin?identifier=${this.pin._id}`, { headers: { 'Authorization': `Bearer ${window.token}` } })).json();

      this.fullPin = result.pin;
      this.comments = result.comments;

      this.loading = false;
    },
    deletePin: function() {
      if (confirm("Ben je zeker dat je deze pin wil verwijderen?") == true) {
        this.$emit('delete', this.pin);
      }
    },
    reply: function(comment) {
      this.replyTo = comment;
      this.comment = '';
    },
    openSubComment: function(comment) {
      this.subcommentfor = comment._id;
      this.subComment = '';
    },
    addReply: async function(comment, commentFor) {
      const options = {
          method: 'POST',
          body: JSON.stringify({
            pinId: commentFor,
            comment: comment
          }),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${window.token}`
          }
      }

      if (comment == '') {
        return;
      }
      
      this.commenttext = '',
      this.subcommenttext = '',
      this.subcommentfor = '';

      // send POST request
      await fetch(`/.netlify/functions/createcomment`, options);

      await this.reloadPin();
    },
    addCommentReply: async function(comment, commentFor) {
      const options = {
          method: 'POST',
          body: JSON.stringify({
            commentId: commentFor,
            comment: comment
          }),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${window.token}`
          }
      }

      if (comment == '') {
        return;
      }
      
      this.subcommenttext = '',
      this.subcommentfor = '';

      // send POST request
      await fetch(`/.netlify/functions/createsubcomment`, options);

      await this.reloadPin();
    }
  },
  props: {
    pin: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      commenttext: '',
      subcommenttext: '',
      subcommentfor: null,
      fullPin: null,
      currentUser: window.me,
      comments: [
        
      ]
    }
  }
}
</script>

<style scoped>

  .reply {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
  }

  .replybutton {
    cursor: pointer;
    flex-grow: 0;
    width: 40px;
    padding-top: 10px;
    font-size: 16px;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: rgb(51, 51, 51);
    background-color: none;
    width: fit-content;
  }


  .comment {
    text-align: left;
  }

  .sub-comment {
    margin-left: 10px;
    padding-left: 10px;
    border-left: 2px solid var(--color-secondary-action);
  }

  .sub-comment-reply {
    cursor: pointer;
    margin-left: 20px;
    margin-bottom: 12px;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--color-secondary-action);
    width: fit-content;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 15px;
  }

  .comment-divider {
    margin-top: 12px;
    margin-bottom: 12px;
    border-top: 1px solid var(--color-secondary-action);
  }

  .text-center {
    text-align: center;
  }
</style>
