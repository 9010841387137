<template>
  <div class="overlay">
    <div class="text">
      <div class=""><img :src="explenation.image" alt="Logo" class="image"></div>
      <div class=""><h1>{{ explenation.title }}</h1></div>
      <div class=""><p>{{ explenation.text }}</p></div>
    </div>

    <div>
      <button class="button" v-if="index != 0" @click="previous">&#8678;</button> 
      <button class="button" v-if="index+1 < explenations.length" @click="next">&#8680;</button>
      <button class="button" v-if="index+1 >= explenations.length" @click="understood">Lets Go!</button>
    </div>

  </div>
</template>

<script>

const explenations = [
  {
    image: require('@/assets/logo-512.png'),
    title: 'Welkom',
    text: 'Kazou Tripadvisor is een app die je helpt om je favoriete plekjes te delen met je Kazou vrienden. Dit is een product Van Vrijwilligers Voor Vrijwilligers.',
  },
  {
    image: require('@/assets/onboarding-explore.png'),
    title: 'Vind Je Bestemming',
    text: 'Zoek eenvoudig jouw bestemming op de kaart met behulp van de zoekbalk, en bekijk al de leuke plekjes in de buurt.',
  },
  {
    image: require('@/assets/onboarding-comment.png'),
    title: 'Vind en Deel Informatie',
    text: 'Vind informatie over plekjes in de buurt waar jij naartoe kan gaan. Ontbreekt er informatie volgens jou? Voeg deze dan zeer gemakkelijk zelf aan de pin toe.',
  },
  {
    image: require('@/assets/onboarding-newpin.png'),
    title: 'Een Nieuwe Ontdekking?',
    text: 'Heb je een nieuwe plekje gevonden? Deel het met al jou Kazou vrienden door een nieuwe pin toe te voegen.',
  },
  {
    image: require('@/assets/onboarding-terrain.png'),
    title: 'Ontdek Het Terrein',
    text: 'Zo kaartlezen is soms lastig. Daarom kan je ook het terrein bekijken en zien waar een locatie zich bevindt.',
  },
]

export default {
  name: 'OnboardingOverlay',
  emits: ['close'],
  components: {

  },
  methods: {
    previous() {
      this.index--;
      this.explenation = this.explenations[this.index];
    },
    next() {
      this.index++;
      this.explenation = this.explenations[this.index];
    },
    understood() {
      this.$emit('close');
    }
  },
  data() {
    return {
      index: 0,
      explenation: explenations[0],
      explenations: explenations,
    }
  }
}
</script>

<style scoped>
  

  .overlay .button {
    background-color: var(--color-primary);
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 20px;
    font-size: 24px;
    font-weight: bolder;
    border-radius: 10px;
  }

  .overlay .button:hover {
    background-color: var(--color-primary-hover);
  }

  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    animation-duration: 0.3s;
    animation-name: slideFromBottom;
    z-index: 100;
    background-color: var(--color-primary-active);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .text {
    margin-left: 60px;
    margin-right: 60px;
    text-align: center;
    color: whitesmoke;
  }

  @keyframes slideFromBottom {
    0% {
      height: 0vh;
    }
    100% {
      height: 100vh;
    }
  } 

  .image {
    width: 256px;
    height: 256px;
    object-fit: cover;
    object-position: center;
  }
</style>
